export const routes = {
  registration: {
    _meta: {
      blocks: ['voter_registration'],
    },
    canvassers: {
      path: '/collect/voter_registration/canvassers',
      permissions: [{ resource: 'canvasser', ability: 'view' }],
      blocks: ['voter_registration'],
    },
    locations: {
      path: '/collect/voter_registration/locations',
      permissions: [{ resource: 'location', ability: 'view' }],
      blocks: ['voter_registration'],
    },
    shifts: {
      path: '/collect/voter_registration/shifts',
      permissions: [{ resource: 'shift', ability: 'view' }],
      blocks: ['voter_registration'],
    },
    deliveries: {
      path: '/collect/voter_registration/deliveries',
      permissions: [{ resource: 'delivery', ability: 'view' }],
      blocks: ['voter_registration'],
    },
    'form archive': {
      path: '/collect/voter_registration/registration_forms',
      permissions: [{ resource: 'registration_form', ability: 'view' }],
      blocks: ['voter_registration'],
    },
    exports: {
      path: '/data_sources/scheduled_exports',
      permissions: [{ resource: 'scheduled_export', ability: 'view' }],
      blocks: ['voter_registration'],
    },
    'field management': {
      path: '/field-management',
      permissions: [
        { resource: 'field_management/goal', ability: 'view' },
        { resource: 'field_management/projection', ability: 'view' },
      ],
      blocks: ['voter_registration'],
    },
  },
  'quality control': {
    _meta: {
      blocks: ['quality_control'],
    },
    'table manager': {
      path: '/quality_control/manage',
      permissions: [
        { resource: 'quality_control/schedule', ability: 'modify' },
      ],
      blocks: ['quality_control'],
    },
    'group manager': {
      path: '/quality_control/flags/review',
      permissions: [{ resource: 'quality_control/flag', ability: 'review' }],
      blocks: ['quality_control'],
    },
    'my worklist': {
      path: '/quality_control/inbox',
      permissions: [{ resource: 'shift', ability: 'visual_qc' }],
      blocks: ['quality_control'],
    },
    'call scripts': {
      path: '/quality_control/call_scripts',
      permissions: [
        {
          resource: 'quality_control/phone_verification_script',
          ability: 'view',
        },
      ],
      blocks: ['quality_control'],
    },
    'call recordings': {
      path: '/quality_control/call_recordings',
      permissions: [
        {
          resource: 'twilio_call',
          ability: 'view',
        },
      ],
      blocks: ['quality_control'],
    },
  },
  organize: {
    _meta: {
      blocks: ['organize', 'events', 'phone_banking', 'engage'],
    },
    'check-in': {
      path: '/check-ins',
      permissions: [{ resource: 'check_in', ability: 'any' }],
      blocks: ['organize', 'reporting'],
    },
    people: {
      path: '/organize/people',
      permissions: [{ resource: 'person', ability: 'view' }],
      blocks: ['organize'],
    },
    meetings: {
      path: '/build/meetings',
      permissions: [{ resource: 'meeting', ability: 'view' }],
      blocks: ['events'],
    },
    events: {
      path: '/build/events',
      permissions: [{ resource: 'event', ability: 'view' }],
      blocks: ['events'],
    },
    locations: {
      path: '/organize/event_locations',
      permissions: [{ resource: 'location', ability: 'view' }],
      blocks: ['events'],
    },
    organizations: {
      path: '/organize/organizations',
      permissions: [{ resource: 'organization', ability: 'view' }],
      blocks: ['organize'],
    },
    teams: {
      path: '/organize/teams',
      permissions: [{ resource: 'team', ability: 'view' }],
      blocks: ['organize'],
    },
    lists: {
      path: '/organize/lists',
      permissions: [{ resource: 'list', ability: 'view' }],
      blocks: ['organize'],
    },
    'send email': {
      path: '/email/manage',
      permissions: [{ resource: 'email_template', ability: 'view' }],
      blocks: ['engage'],
    },
    'phone banking': {
      path: '/organize/phone_banks',
      permissions: [{ resource: 'phone_banking/phone_bank', ability: 'view' }],
      blocks: ['phone_banking'],
    },
    documents: {
      path: '/share/documents',
      permissions: [{ resource: 'document', ability: 'view' }],
      blocks: ['data_sources', 'organize'],
    },
    imports: {
      permissions: [{ resource: 'import', ability: 'view' }],
      path: '/data_sources/imports',
      blocks: ['data_sources', 'organize'],
    },
  },
  petitions: {
    _meta: {
      blocks: ['petitions'],
    },
    shifts: {
      path: '/collect/petitions/shifts',
      permissions: [{ resource: 'shift', ability: 'view' }],
      blocks: ['petitions'],
    },
    canvassers: {
      path: '/collect/petitions/canvassers',
      permissions: [{ resource: 'canvasser', ability: 'view' }],
      blocks: ['petitions'],
    },
    locations: {
      path: '/collect/petitions/locations',
      permissions: [{ resource: 'location', ability: 'view' }],
      blocks: ['petitions'],
    },
  },
  reports: {
    _meta: {
      blocks: ['voter_registration', 'petitions', 'organizing'],
    },
    canvassers: {
      path: '/reporting/reports/canvassers',
      permissions: [
        { resource: 'cover_sheet', ability: 'view' },
        { resource: 'cover_sheet_party_report', ability: 'view' },
        { resource: 'cover_sheet_tablet', ability: 'view' },
        { resource: 'qc_program', ability: 'view' },
        { resource: 'party_report', ability: 'view' },
        { resource: 'party_lean_report', ability: 'view' },
        { resource: 'district_report', ability: 'view' },
        { resource: 'partisan_score_report', ability: 'view' },
      ],
      blocks: ['reporting', 'voter_registration'],
    },
    locations: {
      path: '/reporting/reports/locations',
      permissions: [
        { resource: 'cover_sheet', ability: 'view' },
        { resource: 'cover_sheet_party_report', ability: 'view' },
        { resource: 'cover_sheet_tablet', ability: 'view' },
        { resource: 'qc_program', ability: 'view' },
        { resource: 'party_report', ability: 'view' },
        { resource: 'party_lean_report', ability: 'view' },
        { resource: 'district_report', ability: 'view' },
        { resource: 'partisan_score_report', ability: 'view' },
      ],
      blocks: ['reporting', 'voter_registration'],
    },
    turfs: {
      path: '/reporting/reports/turfs',
      permissions: [
        { resource: 'cover_sheet', ability: 'view' },
        { resource: 'cover_sheet_party_report', ability: 'view' },
        { resource: 'cover_sheet_tablet', ability: 'view' },
        { resource: 'qc_program', ability: 'view' },
        { resource: 'party_report', ability: 'view' },
        { resource: 'party_lean_report', ability: 'view' },
        { resource: 'district_report', ability: 'view' },
        { resource: 'partisan_score_report', ability: 'view' },
      ],
      blocks: ['reporting', 'voter_registration'],
    },
    resources: {
      path: '/reporting/reports/resources',
      permissions: [
        { resource: 'registration_general', ability: 'view' },
        { resource: 'qc_staff', ability: 'view' },
        { resource: 'qc_flags', ability: 'view' },
        { resource: 'qc_program', ability: 'view' },
        { resource: 'qc_workflow', ability: 'view' },
      ],
      blocks: ['reporting', 'voter_registration'],
    },
    users: {
      path: '/reporting/reports/users',
      permissions: [
        { resource: 'qc_staff', ability: 'view' },
        { resource: 'qc_workflow', ability: 'view' },
      ],
      blocks: ['reporting', 'voter_registration'],
    },
    verification: {
      path: '/reporting/reports/verification',
      permissions: [{ resource: 'form_validation', ability: 'view' }],
      blocks: ['reporting', 'voter_registration'],
    },
    catalist: {
      path: '/reporting/reports/catalist_reports',
      permissions: [{ resource: 'catalist_reports', ability: 'view' }],
      blocks: ['reporting', 'catalist'],
    },
    petitions: {
      path: '/reporting/reports/petition_reports',
      permissions: [{ resource: 'petition_general', ability: 'view' }],
      blocks: ['reporting', 'petitions'],
    },
    organizing: {
      path: '/reporting/reports/organizing_reports',
      permissions: [
        { resource: 'report', ability: 'view' },
        { resource: 'event', ability: 'view' },
      ],
      blocks: ['organize', 'reporting'],
    },
    'phone banking': {
      path: '/reporting/reports/phone_bank_reports',
      permissions: [{ resource: 'phone_banks', ability: 'view' }],
      blocks: ['phone_banking', 'reporting'],
    },
  },
}

export const SettingsNavigationRoutes = [
  {
    label: 'users',
    path: '/admin/users',
    permissions: [{ resource: 'user', ability: 'view' }],
  },
  {
    label: 'roles',
    path: '/admin/roles',
    permissions: [{ resource: 'role', ability: 'view' }],
  },
  {
    label: 'turfs',
    path: '/admin/turfs',
    permissions: [{ resource: 'turf', ability: 'view' }],
  },
  {
    label: 'campaigns',
    path: '/admin/campaigns',
    permissions: [{ resource: 'campaign', ability: 'view' }],
  },
]

export const SettingsNavigationRoutesMap = {
  users: {
    label: 'users',
    path: '/admin/users',
    permissions: [{ resource: 'user', ability: 'view' }],
  },
  roles: {
    label: 'roles',
    path: '/admin/roles',
    permissions: [{ resource: 'role', ability: 'view' }],
  },
  turfs: {
    label: 'turfs',
    path: '/admin/turfs',
    permissions: [{ resource: 'turf', ability: 'view' }],
  },
  campaigns: {
    label: 'campaigns',
    path: '/admin/campaigns',
    permissions: [{ resource: 'campaign', ability: 'view' }],
  },
}

const reduceToKeyValuePair = (
  pathToParentMapping,
  [blockName, blockRouteObject]
) => {
  if (blockName !== 'global') {
    Object.entries(blockRouteObject).forEach(([viewName, { path }]) => {
      if (viewName !== '_meta') pathToParentMapping[path] = viewName
    })
  }

  return pathToParentMapping
}

export const rootPathToName = Object.entries(routes).reduce(
  reduceToKeyValuePair, // as [path]: [Navigation Item Name]
  {}
)

export const exceptionalParents = {
  '/quality_control/flags/start': '/quality_control/manage',
}

export const excludedParents = new Set(['/share/documents'])

export const adminSettingsPaths = [
  '/admin/users',
  '/admin/roles',
  '/admin/turfs',
  '/admin/turf_levels',
  '/admin/collections',
  '/admin/campaigns',
]
